export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
  
    // Add ordinal suffix to the day (e.g., 1st, 2nd, 3rd, 4th)
    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // covers 11th to 19th
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    return `${month} ${day}${ordinalSuffix(day)} ${year}`;
  };