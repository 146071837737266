import React from 'react';
import { Project } from '../models/Project';

interface PropertyCardProps {
  project: Project;
  onClick: () => void;
  onLoginClick: () => void;
  isAdmin?: boolean; // Indicates admin or editing mode
  onEditClick?: () => void; // Callback for edit button click
}

const PropertyCard: React.FC<PropertyCardProps> = ({ project, onClick, onLoginClick, isAdmin = false, onEditClick }) => {

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the main card click event
    onEditClick && onEditClick();
  };

  return (
    <div
      className={`relative block card w-full bg-white shadow-xl border border-gray-300 hover:shadow-2xl transition-shadow duration-300 rounded-lg ${project.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={!project.disabled ? onClick : undefined}
    >
      {isAdmin && (
        <button 
          className="absolute top-2 z-10 right-2 bg-white p-1 rounded-full shadow-lg hover:bg-gray-100 transition duration-200"
          onClick={handleEditClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-700">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-6.036a2.5 2.5 0 113.536 3.536L7.5 20.5H4v-3.5L16.732 3.732z" />
          </svg>
        </button>
      )}

      {/* Property Image */}
      <figure className="relative h-60 md:h-48 overflow-hidden rounded-t-lg"> {/* Responsive height */}
        <img
          src={project.generalPicturesUrls[0] || project.projectFeatures.units?.[0]?.pictures?.[0] || '/path/to/default-image.jpg'} // Use a fallback image if pictures[0] is unavailable
          className="h-full w-full object-cover"
          alt="Property"
          style={{ pointerEvents: 'none' }} // Prevents the image from blocking clicks on the card
        />
        {project.developer?.logo && (
          <img
            src={project.developer.logo}
            alt="Developer Logo"
            className="absolute bottom-0 right-0 h-8 w-30 object-contain bg-white rounded-tl-lg border-white border"
            style={{ pointerEvents: 'none' }}
          />
        )}
      </figure>

      <div className="card-body p-4 relative">
        {/* Property Name */}
        <h2 className="card-title text-gray-900 font-bold text-xl mb-2">
          {project.name}
        </h2>

        <p className="text-gray-700 text-base mt-2">
          {(() => {
            const sizes = project.projectFeatures.units
              ?.map(unit => Number(unit.buildingArea)) // Ensure sizes are numbers
              .filter(size => size > 0) || []; // Filter out 0 or invalid sizes

            if (sizes.length === 0) return null; // Do not render if no valid sizes exist

            const minSize = Math.min(...sizes);
            const maxSize = Math.max(...sizes);

            return (
              <>
                <span className="font-semibold text-gray-800">Size:</span>{' '}
                {minSize === maxSize ? `${minSize} m²` : `${minSize} - ${maxSize} m²`}
              </>
            );
          })()}
        </p>
        {/* Property Prices */}
        <p className="text-gray-700 text-base mt-1">
          <span className="font-semibold text-gray-800">Price:</span>{' '}
          {(() => {
            const prices = project.projectFeatures.units?.map(unit => unit.price) || [];
            const minPrice = Math.min(...prices);
            const maxPrice = Math.max(...prices);

            // Helper function to format price
            const formatPrice = (price) => {
              if (price % 1000 === 0) {
                return `$${(price / 1000).toLocaleString()}k`; // Convert thousands to 'k'
              }
              return `$${price.toLocaleString()}`; // Format normally
            };

            return minPrice === maxPrice
              ? formatPrice(minPrice) // Display single price
              : `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`; // Display range
          })()}
        </p>

        {(() => {
          const { minROI, maxROI } = project.investmentInformation;

          if (minROI === 0 && maxROI === 0) {
            return null; // Don't show ROI if both are 0
          }

          return (
            <p className="text-base mt-1">
              <span className="font-semibold text-green-600">ROI:</span>{' '}
              <span className="text-gray-700">
                {minROI === maxROI ? `${maxROI}%` : `${minROI}% - ${maxROI}%`}
              </span>
            </p>
          );
        })()}
      </div>
    </div>
  );
};

export default PropertyCard;
