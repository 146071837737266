import React from 'react';
import { City } from '../models/City'; // Ensure the correct path for importing City model

interface DropDownProps {
  cities: City[];
  selectedCity: string | null;
  onSelectCity: (cityName: string) => void;
  className?: string; // Add className as an optional prop
}

const DropDown: React.FC<DropDownProps> = ({ cities, selectedCity, onSelectCity, className }) => {
  return (
    <div className={`relative w-full sm:w-auto ${className ?? ''}`}>
      <select
        className="w-full sm:w-60 px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-gray-700 appearance-none"
        style={{ WebkitAppearance: 'none', MozAppearance: 'none', appearance: 'none' }}
        value={selectedCity ?? ''} // Use empty string if selectedCity is null
        onChange={(e) => onSelectCity(e.target.value)}
      >
        <option value="" disabled>
          Select a city
        </option>
        {cities.map((city) => (
          <option key={city._id} value={city.name}>
            {city.name}, {city.country}
          </option>
        ))}
      </select>

      {/* Optional: Custom Dropdown Arrow */}
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg
          className="w-4 h-4 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default DropDown;
