import { useState } from "react";
import apiClient from "../api/apiClientInterceptor";

interface UploadResult {
  result: any; // Replace `any` with the expected shape of the result if known
  message: any;
}

const usePdfUpload = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadPdf = async (
    file: File,
    onSuccess: (result: any) => void, // Adjust `any` to match the result structure
    onError?: (error: string) => void
  ) => {
    try {
      setUploading(true);
      setError(null);

      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post<UploadResult>("project/analyzePDF", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200 && response.data?.result) {
        onSuccess(response.data.result);
      } else {
        const errorMessage = response.data?.message || "Failed to process the PDF.";
        setError(errorMessage);
        if (onError) onError(errorMessage);
      }
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || "An error occurred while uploading the PDF.";
      setError(errorMessage);
      if (onError) onError(errorMessage);
    } finally {
      setUploading(false);
    }
  };

  return { uploadPdf, uploading, error };
};

export default usePdfUpload;
