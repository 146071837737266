import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useProject from '../hooks/useProject';
import useAmenities from '../hooks/useAmenities';
import Loader from '../components/Loader';
import { Project } from '../models/Project';
import PdfUploader from "../components/PdfUploader";
import apiClient from '../api/apiClientInterceptor';
import DeveloperDropdown from '../components/DeveloperDropdown';

interface Amenity {
    _id: string;
    name: string;
    type: 'shared' | 'private';
    icon: string;
}

interface FormData {
    name: string;
    cityName: string;
    cityArea: string;
    country: string;
    description: string;
    developerName: string;
    presentationPdfUrl: string;
    financialModelUrl: string;
    generalPicturesUrls: {
        url: string;
        file: File | null;
    }[];
    projectFeatures: {
        totalLandArea: string;
        completionDate: string;
        unitsAvailable: string;
        yearsOfLease: string;
        units: {
            unitName: string;
            price: string;
            buildingArea: string;
            bedrooms: string;
            pictures: {
                url: string;
                file: File | null;
            }[];
            description: string;
            unitsAvailable: string;
            salesDetails: {
                status: string
            },
            amenities: {
                privateAmenities: Record<string, boolean>;
            };
            identifier: string;

        }[];
    };
    constructionDetails: {
        constructionProgress?: string;
        constructionProgressDetails: string,
        warrantyPeriod?: string;
        constructionMaterials: string;
    };
    investmentInformation: {
        minROI: string;
        maxROI: string;
        breakEvenPeriod?: string;
        expectedROI?: string;
    };
    amenities: {
        sharedAmenities: Record<string, boolean>;
    };
}

const defaultFormData: FormData = {
    name: '',
    cityName: '',
    cityArea: '',
    country: '',
    description: '',
    generalPicturesUrls: [],
    developerName: '',
    presentationPdfUrl: '',
    financialModelUrl: '',
    projectFeatures: {
        totalLandArea: '',
        completionDate: '',
        unitsAvailable: '',
        yearsOfLease: '',
        units: [
            {
                unitName: '',
                price: '',
                buildingArea: '',
                pictures: [{ url: '', file: null }], // Initialize with an empty array
                description: '',
                bedrooms: '',
                unitsAvailable: '',
                amenities: { privateAmenities: {} },
                identifier: '',
                salesDetails: {
                    status: 'For Sale'
                }
            }
        ]
    },
    constructionDetails: {
        constructionProgress: '',
        constructionProgressDetails: '',
        warrantyPeriod: '',
        constructionMaterials: ''
    },
    investmentInformation: {
        minROI: '',
        maxROI: '',
        breakEvenPeriod: '',
        expectedROI: ''
    },
    amenities: { sharedAmenities: {} }
};

const LOCAL_STORAGE_KEY = 'projectFormData';

const AddOrEditProject: React.FC = () => {
    const { identifier } = useParams<{ identifier: string }>();
    const [loading, setLoading] = useState(true);
    const [showUpload, setShowUpload] = useState(true);
    const [developers, setDevelopers] = useState<{ _id: string; name: string }[]>([]); // Developer list
    const [errors, setErrors] = useState<Record<string, string>>({});
    const navigate = useNavigate();
    const storedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);
    const [formData, setFormData] = useState<FormData>(
        storedFormData ? JSON.parse(storedFormData) : defaultFormData
    );
    const inputRefs = useRef<{
        [key: string]: HTMLInputElement | HTMLTextAreaElement | null | Record<string, HTMLInputElement | HTMLTextAreaElement | null>;
    }>({});
    const [availableAmenities, setAvailableAmenities] = useState<Amenity[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null);
    const [activeTab, setActiveTab] = useState<'general' | 'units'>('general');
    const setInputRef = (
        fieldName: string, 
        element: HTMLInputElement | HTMLTextAreaElement | null
    ): void => {
        inputRefs.current[fieldName] = element;
    };
    
    const setNestedInputRef = (
        unitIndex: number,
        fieldName: string,
        element: HTMLInputElement | HTMLTextAreaElement | null
    ): void => {
        if (!inputRefs.current[`unit-${unitIndex}`]) {
            inputRefs.current[`unit-${unitIndex}`] = {}; // Initialize as a nested object
        }
        (inputRefs.current[`unit-${unitIndex}`] as Record<string, HTMLInputElement | HTMLTextAreaElement | null>)[fieldName] = element;
    };
      

    const isFormEmpty = (formData: FormData): boolean => {
        const hasGeneralData = 
            formData.name || 
            formData.cityName || 
            formData.cityArea || 
            formData.country || 
            formData.description || 
            formData.developerName || 
            formData.presentationPdfUrl || 
            formData.financialModelUrl;
    
        const hasGeneralPictures = formData.generalPicturesUrls.some((pic) => pic.url || pic.file);
    
        const hasProjectFeatures = 
            formData.projectFeatures.totalLandArea ||
            formData.projectFeatures.completionDate ||
            formData.projectFeatures.unitsAvailable ||
            formData.projectFeatures.yearsOfLease ||
            formData.projectFeatures.units.some(
                (unit) =>
                    unit.unitName || 
                    unit.price || 
                    unit.buildingArea || 
                    unit.description || 
                    unit.bedrooms || 
                    unit.unitsAvailable || 
                    unit.pictures.some((pic) => pic.url || pic.file)
            );
    
        const hasConstructionDetails = 
            formData.constructionDetails.constructionProgress || 
            formData.constructionDetails.constructionProgressDetails || 
            formData.constructionDetails.warrantyPeriod || 
            formData.constructionDetails.constructionMaterials;


    
        const hasInvestmentInfo =
            formData.investmentInformation.minROI || 
            formData.investmentInformation.maxROI || 
            formData.investmentInformation.breakEvenPeriod || 
            formData.investmentInformation.expectedROI;
    
        const hasAmenities = Object.values(formData.amenities.sharedAmenities).some((value) => value);
    
        return !(
            hasGeneralData ||
            hasGeneralPictures ||
            hasProjectFeatures ||
            hasConstructionDetails ||
            hasInvestmentInfo ||
            hasAmenities
        );
    };

    const handleClearForm = () => {
        setFormData(defaultFormData);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    useEffect(() => {
        const fetchAmenitiesAndProjectData = async () => {
            setLoading(true);
    
            try {
                const amenities = await useAmenities.getAllAmenities();
                const sharedAmenities = amenities
                    .filter((amenity) => amenity.type === 'shared')
                    .reduce((acc, amenity) => ({ ...acc, [amenity.name]: false }), {} as Record<string, boolean>);
                setAvailableAmenities(amenities);
    
                const storedFormData = localStorage.getItem(LOCAL_STORAGE_KEY);
                let initialFormData = storedFormData
                    ? JSON.parse(storedFormData)
                    : { ...defaultFormData, amenities: { sharedAmenities } };
    
                if (identifier) {
                    const project = await useProject.getDetails(identifier);
                    const projectData = project.data;

                    console.log('my project data', project.data)
    
                    // Map the project data into the formData structure
                    initialFormData = {
                        ...initialFormData,
                        name: projectData.name || '',
                        cityName: projectData.cityName || '',
                        cityArea: projectData.cityArea || '',
                        country: projectData.country || '',
                        description: projectData.description || '',
                        developerName: projectData.developer?.name || '',
                        presentationPdfUrl: projectData.presentationPdfUrl || '',
                        financialModelUrl: projectData.financialModelUrl || '',
                        projectFeatures: {
                            ...initialFormData.projectFeatures,
                            totalLandArea: String(projectData.projectFeatures?.totalLandArea || ''),
                            completionDate: projectData.projectFeatures?.completionDate
                                ? new Date(projectData.projectFeatures.completionDate).toISOString().split('T')[0]
                                : '',
                            yearsOfLease: String(projectData.projectFeatures?.yearsOfLease || ''),
                            unitsAvailable: String(projectData.projectFeatures?.unitsAvailable || ''),
                            units: (projectData.projectFeatures?.units || []).map(unit => ({
                                unitName: unit.unitName || '',
                                price: String(unit.price || ''),
                                buildingArea: String(unit.buildingArea || ''),
                                pictures: (unit.pictures || []).map((picture: string) => ({ url: picture, file: null })),
                                description: unit.description || '',
                                bedrooms: String(unit.bedrooms || ''),
                                identifier: unit.identifier || '',
                                unitsAvailable: String(unit.unitsAvailable || ''),
                                salesDetails: {
                                    status: unit.salesDetails?.status || ''
                                },
                                amenities: {
                                    privateAmenities: availableAmenities
                                        .filter(amenity => amenity.type === 'private')
                                        .reduce((acc, amenity) => {
                                            acc[amenity.name] = unit?.amenities?.includes(amenity._id) || false;
                                            return acc;
                                        }, {} as Record<string, boolean>)
                                }
                            }))
                        },
                        constructionDetails: {
                            constructionProgress: String(projectData.constructionDetails?.constructionProgress || ''),
                            constructionProgressDetails: projectData.constructionDetails?.constructionProgressDetails || '',
                            warrantyPeriod: String(projectData.constructionDetails?.warrantyPeriod || ''),
                            constructionMaterials: projectData.constructionDetails?.constructionMaterials || ''
                        },  
                        investmentInformation: {
                            minROI: String(projectData.investmentInformation?.minROI || ''),
                            maxROI: String(projectData.investmentInformation?.maxROI || ''),
                            breakEvenPeriod: String(projectData.investmentInformation?.breakEvenPeriod || ''),
                            expectedROI: String(projectData.investmentInformation?.expectedROI || '')
                        },
                        amenities: {
                            sharedAmenities: {
                                ...sharedAmenities,
                                ...projectData.amenities?.sharedAmenities,
                            },
                        },
                    };
                    console.log('myyy', initialFormData.constructionDetails.constructionProgressDetails)
                }
    
                setFormData(initialFormData);
            } catch (err) {
                console.error('Failed to load data:', err);
                setError('Failed to load project data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
        fetchAmenitiesAndProjectData();
    }, [identifier]);

    const handleDeveloperSelect = (developerName: string) => {
        setFormData((prev) => ({ ...prev, developerName: developerName }));
    };

    const handleProcessComplete = (data: any) => {
        // Safely update the formData with uploaded data
        setFormData((prevFormData) => ({
            ...prevFormData,
            // Update general project fields
            name: data.name || prevFormData.name || '',
            cityName: data.cityName || prevFormData.cityName || '',
            cityArea: data.cityArea || prevFormData.cityArea || '',
            country: data.country || prevFormData.country || '',
            description: data.description || prevFormData.description || '',
            
            // Update nested constructionDetails
            constructionDetails: {
                ...prevFormData.constructionDetails,
                constructionProgress: data.constructionDetails?.constructionProgress || prevFormData.constructionDetails?.constructionProgress || '',
                constructionProgressDetails: data.constructionDetails?.constructionProgressDetails || prevFormData.constructionDetails?.constructionProgressDetails || '',
                warrantyPeriod: data.constructionDetails?.warrantyPeriod || prevFormData.constructionDetails?.warrantyPeriod || '',
                constructionMaterials: data.constructionDetails?.constructionMaterials || prevFormData.constructionDetails?.constructionMaterials || '',
            },
            
            // Update nested projectFeatures
            projectFeatures: {
                ...prevFormData.projectFeatures,
                totalLandArea: data.projectFeatures?.totalLandArea || prevFormData.projectFeatures?.totalLandArea || '',
                completionDate: data.projectFeatures?.completionDate || prevFormData.projectFeatures?.completionDate || '',
                unitsAvailable: data.projectFeatures?.unitsAvailable || prevFormData.projectFeatures?.unitsAvailable || '',
                yearsOfLease: data.projectFeatures?.yearsOfLease || prevFormData.projectFeatures?.yearsOfLease || '',
                units: data.projectFeatures?.units?.map((unit: any, index: number) => ({
                    unitName: unit.unitName || prevFormData.projectFeatures.units[index]?.unitName || '',
                    price: unit.price || prevFormData.projectFeatures.units[index]?.price || '',
                    buildingArea: unit.buildingArea || prevFormData.projectFeatures.units[index]?.buildingArea || '',
                    pictures: unit.pictures || prevFormData.projectFeatures.units[index]?.pictures || [],
                    description: unit.description || prevFormData.projectFeatures.units[index]?.description || '',
                    unitsAvailable: unit.unitsAvailable || prevFormData.projectFeatures.units[index]?.unitsAvailable || '',
                    salesDetails: {
                        status: unit.status || prevFormData.projectFeatures.units[index]?.salesDetails.status || ''
                    },
                    amenities: unit.amenities || prevFormData.projectFeatures.units[index]?.amenities || { privateAmenities: {} },
                })) || prevFormData.projectFeatures.units,
            },
            
            // Update nested investmentInformation
            investmentInformation: {
                ...prevFormData.investmentInformation,
                minROI: data.investmentInformation?.minROI || prevFormData.investmentInformation?.minROI || '',
                maxROI: data.investmentInformation?.maxROI || prevFormData.investmentInformation?.maxROI || '',
                breakEvenPeriod: data.investmentInformation?.breakEvenPeriod || prevFormData.investmentInformation?.breakEvenPeriod || '',
                expectedROI: data.investmentInformation?.expectedROI || prevFormData.investmentInformation?.expectedROI || '',
            },
            
            // Update nested amenities
            amenities: {
                ...prevFormData.amenities,
                sharedAmenities: data.amenities?.sharedAmenities || prevFormData.amenities?.sharedAmenities || {},
            },
        }));
    };    

    const addUnitPictureField = (unitIndex: number) => {
        setFormData((prevFormData) => {
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) =>
                idx === unitIndex
                    ? {
                          ...unit,
                          pictures: [...unit.pictures, { url: '', file: null }],
                      }
                    : unit
            );
    
            return {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits,
                },
            };
        });
    };

    const removeUnitPictureField = (unitIndex: number, pictureIndex: number) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: prevFormData.projectFeatures.units.map((unit, idx) =>
                        idx === unitIndex
                            ? {
                                  ...unit,
                                  pictures: unit.pictures.filter((_, picIdx) => picIdx !== pictureIndex)
                              }
                            : unit
                    )
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };
    
    const addUnit = () => {
        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: [
                        ...(prevFormData.projectFeatures.units || []),
                        {
                            unitName: '',
                            price: '',
                            buildingArea: '',
                            pictures: [], // Initialize pictures array as empty
                            description: '',
                            unitsAvailable: '',
                            identifier: '',
                            bedrooms: '',
                            salesDetails: {
                                status: ''
                            },
                            amenities: {
                                privateAmenities: {}, // Initialize privateAmenities as an empty object
                            },
                        },
                    ],
                },
            };
            // Save updated formData to localStorage
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };    
    
    const removeUnit = (unitIndex: number) => {
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: prevFormData.projectFeatures.units.filter((_, index) => index !== unitIndex)
                }
            };
    
            // Save the updated formData to localStorage after removal
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
    
            return updatedFormData;
        });
    };
    
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        nestedField?: 'sharedAmenities' | 'privateAmenities' | 'projectFeatures' | 'investmentInformation' | 'constructionDetails' | 'salesDetails',
        index?: number
    ) => {
        const { name, value, type } = e.target;
        let inputValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
        // Prevent invalid numbers for numeric fields
        if (name === "unitsAvailable" || name === "totalLandArea" || name === "yearsOfLease") {
            if (value === "" || /^\d*$/.test(value)) {
                inputValue = value; // Allow empty string or numeric input only
            } else {
                return; // Ignore invalid input
            }
        }
    
        setFormData((prevFormData) => {
            let updatedFormData;
    
            if (nestedField === 'sharedAmenities' || nestedField === 'privateAmenities') {
                updatedFormData = {
                    ...prevFormData,
                    amenities: {
                        ...prevFormData.amenities,
                        [nestedField]: {
                            ...prevFormData.amenities[nestedField],
                            [name]: inputValue
                        }
                    }
                };
            } else if (nestedField === 'projectFeatures') {
                updatedFormData = {
                    ...prevFormData,
                    projectFeatures: {
                        ...prevFormData.projectFeatures,
                        [name]: inputValue
                    }
                };
            } else if (nestedField === 'investmentInformation') {
                updatedFormData = {
                    ...prevFormData,
                    investmentInformation: {
                        ...prevFormData.investmentInformation,
                        [name]: inputValue
                    }
                };
            } else if (nestedField === 'constructionDetails') {
                updatedFormData = {
                    ...prevFormData,
                    constructionDetails: {
                        ...prevFormData.constructionDetails,
                        [name]: inputValue
                    }
                };
            } else if (nestedField === 'salesDetails' && typeof index === 'number') {
                const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) => {
                    if (idx === index) {
                        return {
                            ...unit,
                            salesDetails: {
                                ...unit.salesDetails,
                                status: inputValue,
                            },
                        };
                    }
                    return unit;
                });
    
                updatedFormData = {
                    ...prevFormData,
                    projectFeatures: {
                        ...prevFormData.projectFeatures,
                        units: updatedUnits,
                    },
                };
            } else {
                updatedFormData = { ...prevFormData, [name]: inputValue as string };
            }
    
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    
        // Remove the error for the field being modified
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            
            // Remove nested field errors dynamically
            if (nestedField === 'projectFeatures') {
                delete updatedErrors[`projectFeatures.${name}`];
            } else if (nestedField === 'investmentInformation') {
                delete updatedErrors[`investmentInformation.${name}`];
            } else {
                delete updatedErrors[name];
            }
    
            return updatedErrors;
        });
    };
    
    const handleUnitChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        unitIndex: number,
        nestedField?: 'sharedAmenities' | 'privateAmenities'
    ) => {
        const { name, value, type } = e.target;
        let inputValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
        // For numeric fields, allow only valid numbers or empty input
        if (name === 'unitsAvailable' || name === 'price') {
            if (value === '' || /^\d*$/.test(value)) {
                inputValue = value; // Accept empty or valid numeric input
            } else {
                return; // Ignore invalid input
            }
        }
    
        setFormData((prevFormData) => {
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) => {
                if (idx === unitIndex) {
                    if (nestedField) {
                        return {
                            ...unit,
                            amenities: {
                                ...unit.amenities,
                                [nestedField]: {
                                    ...unit.amenities[nestedField],
                                    [name]: inputValue,
                                },
                            },
                        };
                    }
                    return { ...unit, [name]: inputValue };
                }
                return unit;
            });
    
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits,
                },
            };
    
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    
        // Clear error for the field being updated
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`projectFeatures.units[${unitIndex}].${name}`];
            return updatedErrors;
        });
    };

    const applyAmenitiesToAllUnits = (sourceUnitIndex: number) => {
        setFormData((prevFormData) => {
            const sourceUnit = prevFormData.projectFeatures.units[sourceUnitIndex];
    
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, index) => {
                if (index === sourceUnitIndex) {
                    // Skip the source unit
                    return unit;
                }
    
                // Apply source unit amenities to other units
                return {
                    ...unit,
                    amenities: {
                        ...unit.amenities,
                        privateAmenities: { ...sourceUnit.amenities.privateAmenities },
                    },
                };
            });
    
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits,
                },
            };
    
            // Save updated formData to localStorage
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
    
            return updatedFormData;
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Perform validation
        const { isValid, errors } = validateForm(formData);
        setErrors(errors); // Set the errors state for rendering messages

        // If the form is invalid, prevent submission and focus on the first invalid field
        if (!isValid) {
            for (const key of Object.keys(errors)) {
                const match = key.match(/units\[(\d+)\]\.(\w+)/);
                if (match) {
                    const unitIndex = Number(match[1]);
                    const fieldName = match[2];
                    const nestedRef = (inputRefs.current[`unit-${unitIndex}`] as Record<string, HTMLInputElement | HTMLTextAreaElement | null>)?.[fieldName];
                    if (nestedRef) {
                        nestedRef.focus();
                        break;
                    }
                } else {
                    const ref = inputRefs.current[key] as HTMLInputElement | HTMLTextAreaElement | null;
                    if (ref) {
                        ref.focus();
                        break;
                    }
                }
            }
            return;
        }

        setLoading(true);
    
        const uploadPicture = async (file: File): Promise<string | null> => {
            const uploadFormData = new FormData();
            uploadFormData.append('file', file);
    
            try {
                const response = await apiClient.post('project/uploadPicture', uploadFormData);
                if (response.status === 200) {
                    const { picture } = response.data; // Assuming `picture` contains the uploaded file URL
                    return picture;
                } else {
                    console.error(`File upload failed with status: ${response.status}`);
                    return null;
                }
            } catch (error) {
                setLoading(false);
                console.error('Error uploading file:', error);
                return null;
            }
        };
    
        // Upload general pictures (files or URLs)
        const generalPicturesUrls = await Promise.all(
            formData.generalPicturesUrls.map(async (picture) => {
                if (picture.file) {
                    const uploadedUrl = await uploadPicture(picture.file);
                    return uploadedUrl || ''; // Add uploaded URL or empty string if upload fails
                }
                return picture.url; // Keep the existing URL if no file is provided
            })
        );
    
        // Upload unit pictures (files or URLs)
        const processedUnits = await Promise.all(
            formData.projectFeatures.units.map(async (unit) => {
                const processedPictures = await Promise.all(
                    unit.pictures.map(async (picture) => {
                        if (typeof picture === 'object' && picture.file) {
                            const uploadedUrl = await uploadPicture(picture.file);
                            return uploadedUrl || ''; // Add uploaded URL or empty string if upload fails
                        }
                        return picture.url || ''; // Keep existing URLs or empty strings
                    })
                );
    
                return {
                    ...unit,
                    pictures: processedPictures.filter(Boolean), // Remove empty strings
                };
            })
        );

        console.log('my units', processedUnits)

        const formattedCompletionDate = formData.projectFeatures?.completionDate || ""; // Already stored as YYYY-MM-DD
    
        // Prepare the submission data
        const submissionData: Project = {
            ...formData,
            generalPicturesUrls: generalPicturesUrls.filter(Boolean), // Use processed general pictures
            projectFeatures: {
                ...formData.projectFeatures,
                totalLandArea: parseFloat(formData.projectFeatures.totalLandArea) || 0,
                unitsAvailable: parseInt(formData.projectFeatures.unitsAvailable) || 0,
                completionDate: formattedCompletionDate,
                yearsOfLease: parseFloat(formData.projectFeatures.yearsOfLease) || 0,
                units: processedUnits.map((unit) => {
                    const cleanPrice = unit.price && !isNaN(Number(unit.price)) ? parseFloat(unit.price) : 0;
                    const cleanBuildingArea = unit.buildingArea && !isNaN(Number(unit.buildingArea)) ? parseFloat(unit.buildingArea) : 0;
                    const cleanUnitsAvailable = unit.unitsAvailable && !isNaN(Number(unit.unitsAvailable)) ? parseFloat(unit.unitsAvailable) : 0;
                
                    return {
                        unitName: unit.unitName?.trim() || '', // Ensure it's a clean string
                        price: cleanPrice, // Cleaned price
                        buildingArea: cleanBuildingArea, // Cleaned building area
                        pictures: unit.pictures || [], // Ensure pictures are valid
                        bedrooms: unit.bedrooms?.trim() || '', // Clean string
                        description: unit.description?.trim() || '', // Clean string
                        identifier: unit.identifier?.trim() || '', // Clean string
                        salesDetails: {
                            status: unit.salesDetails?.status as 'For Sale' | 'Booked' | 'Presale' || 'For Sale',
                        },
                        unitsAvailable: cleanUnitsAvailable, // Cleaned units available
                        amenities: Object.keys(unit.amenities.privateAmenities || {}).filter(
                            (key) => unit.amenities.privateAmenities[key]
                        ),
                    };
                })
            },
            constructionDetails: {
                constructionProgress: formData.constructionDetails.constructionProgress
                    ? parseFloat(formData.constructionDetails.constructionProgress)
                    : undefined,
                constructionProgressDetails: formData.constructionDetails.constructionProgressDetails as 'Project Planning' | 'Under Construction' | 'Completed',
                warrantyPeriod: formData.constructionDetails.warrantyPeriod
                    ? parseInt(formData.constructionDetails.warrantyPeriod)
                    : undefined,
                constructionMaterials: formData.constructionDetails.constructionMaterials,
            },
            investmentInformation: {
                minROI: parseFloat(formData.investmentInformation.minROI) || 0,
                maxROI: parseFloat(formData.investmentInformation.maxROI) || 0,
                breakEvenPeriod: formData.investmentInformation.breakEvenPeriod
                    ? parseFloat(formData.investmentInformation.breakEvenPeriod)
                    : undefined,
                expectedROI: formData.investmentInformation.expectedROI
                    ? parseFloat(formData.investmentInformation.expectedROI)
                    : undefined,
            },
            amenities: formData.amenities.sharedAmenities, // Include shared amenities
            presentationPdfUrl: formData.presentationPdfUrl?.trim() || '',
            financialModelUrl: formData.financialModelUrl?.trim() || ''
        };
    
        // Submit the project data to the backend
        try {
            let response;
            if (identifier) {
                // Update an existing project
                response = await useProject.updateProject(identifier, submissionData);
            } else {
                // Add a new project
                response = await useProject.addProject(submissionData);
            }
    
            if (response.status === 200 || response.status === 201) {
                setSubmissionStatus('success');
                setFormData(defaultFormData); // Reset form data on success
                setError(null);
                localStorage.removeItem(LOCAL_STORAGE_KEY); // Clear localStorage
                navigate('/my-projects'); // Navigate to the admin page
            } else {
                throw new Error('Failed to submit the project');
            }
        } catch (err) {
            console.error('Submission error:', err);
            setSubmissionStatus('error');
            setError("An error occurred. Please check your inputs.");
        } finally {
            setLoading(false);
        }
    };    

    const handleTabSwitch = (tab: 'general' | 'units') => {
        setActiveTab(tab);
    };

    const validateForm = (formData: FormData): { isValid: boolean; errors: Record<string, string> } => {
        const errors: Record<string, string> = {};

        console.log(errors)
      
        // General Information
        if (!formData.name) errors['name'] = 'Project Name is required.';
        if (!formData.cityName) errors['cityName'] = 'City Name is required.';
        if (!formData.cityArea) errors['cityArea'] = 'City Area is required.';
        if (!formData.country) errors['country'] = 'Country is required.';
        if (!formData.description) errors['description'] = 'Description is required.';
      
        // Project Features
        const projectFeatures = formData.projectFeatures;
        const totalUnitsAvailable = Number(projectFeatures.unitsAvailable);
        const yearsOfLease = Number(projectFeatures.yearsOfLease);
      
        if (!totalUnitsAvailable || totalUnitsAvailable <= 0) 
          errors['projectFeatures.unitsAvailable'] = 'Total Units Available must be greater than 0.';
        if (!yearsOfLease) 
          errors['projectFeatures.yearsOfLease'] = 'Years of Lease is required.';
      
        // Units Validation
        if (projectFeatures.units.length === 0) {
          errors['projectFeatures.units'] = 'At least one Unit is required.';
        } else {
          projectFeatures.units.forEach((unit, index) => {
            const unitPrice = Number(unit.price);
            const unitsAvailable = Number(unit.unitsAvailable);
      
            if (!unit.unitName) errors[`projectFeatures.units[${index}].unitName`] = `Unit ${index + 1}: Unit Name Type is required.`;
            if (!unitPrice || unitPrice <= 0)
              errors[`projectFeatures.units[${index}].price`] = `Unit ${index + 1}: Price must be greater than 0.`;
            if (!unitsAvailable || unitsAvailable <= 0)
              errors[`projectFeatures.units[${index}].unitsAvailable`] = `Unit ${index + 1}: Units Available must be greater than 0.`;
          });
        }
      
        // Investment Information
        const investment = formData.investmentInformation;
        const minROI = Number(investment.minROI);
        const maxROI = Number(investment.maxROI);
      
        if (!minROI) errors['investmentInformation.minROI'] = 'Minimum ROI is required.';
        if (!maxROI) errors['investmentInformation.maxROI'] = 'Maximum ROI is required.';
      
        // PDF URLs Validation
        const urlRegex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/; // General URL validation regex

        if (formData.presentationPdfUrl && !urlRegex.test(formData.presentationPdfUrl)) {
            errors['presentationPdfUrl'] = 'Presentation URL must be a valid link.';
        }

        if (formData.financialModelUrl && !urlRegex.test(formData.financialModelUrl)) {
            errors['financialModelUrl'] = 'Financial Model URL must be a valid link.';
        }
      
        return { isValid: Object.keys(errors).length === 0, errors };
    };

    const handleUnitPictureChange = (unitIndex: number, pictureIndex: number, field: 'url' | 'file', value: string | File) => {
        setFormData(prevFormData => {
            const updatedUnits = prevFormData.projectFeatures.units.map((unit, idx) =>
                idx === unitIndex
                    ? {
                          ...unit,
                            pictures: unit.pictures.map((picture, picIdx) =>
                                picIdx === pictureIndex ? { ...picture, [field]: value } : picture
                            ),
                      }
                    : unit
            );
            const updatedFormData = {
                ...prevFormData,
                projectFeatures: {
                    ...prevFormData.projectFeatures,
                    units: updatedUnits
                }
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };

    if (loading) return <Loader />;

    return (
        <div className="min-h-screen bg-gray-100 p-6 flex justify-center items-center">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-2xl w-full">
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
                    {identifier ? 'Edit Project' : 'Add Project'}
                </h2>
                {submissionStatus === 'success' && (
                    <p className="mt-4 text-green-600 text-center">Submitted successfully!</p>
                )}
                {submissionStatus === 'error' && (
                    <p className="mt-4 text-red-600 text-center">Failed to submit. Please try again.</p>
                )}
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}

                <div className="flex mb-4">
                    <button
                        className={`flex-1 py-2 ${activeTab === 'general' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => handleTabSwitch('general')}
                    >
                        General Information
                    </button>
                    <button
                        className={`flex-1 py-2 ${activeTab === 'units' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => handleTabSwitch('units')}
                    >
                        Unit-Specific Information
                    </button>
                </div>


                {/* FIRST TAB */}

                <form onSubmit={handleSubmit} className="space-y-4">
                    {activeTab === 'general' && (
                        <>
                        {!isFormEmpty(formData) && (
                            <button
                                type="button"
                                onClick={handleClearForm}
                                className="w-full py-2 px-4 bg-white text-black font-semibold rounded-md transition duration-300"
                            >
                                Clear Form
                            </button>
                        )}
                        {/* Developer Dropdown */}
                        <DeveloperDropdown
                            selectedDeveloper={formData.developerName}
                            onSelect={handleDeveloperSelect}
                        />
                            { showUpload && (
                                <>
                                <PdfUploader onProcessComplete={handleProcessComplete} setLoading={setLoading}/>
                                </>
                            )}
                        <div>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Project Name" className="w-full p-2 border" ref={(el) => setInputRef('name', el)}/>
                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                        </div>
                        
                        <div>
                            <input
                                type="text"
                                name="cityName"
                                value={formData.cityName}
                                onChange={handleChange}
                                placeholder="City Name"
                                className="w-full p-2 border"
                                ref={(el) => setInputRef('cityName', el)} // Register input reference
                            />
                            {errors.cityName && <p className="text-red-500 text-sm">{errors.cityName}</p>} {/* Error message */}
                        </div>

                        <div>
                            <input
                                type="text"
                                name="cityArea"
                                value={formData.cityArea}
                                onChange={handleChange}
                                placeholder="City Area"
                                className="w-full p-2 border"
                                ref={(el) => setInputRef('cityArea', el)} // Register input reference
                            />
                            {errors.cityArea && <p className="text-red-500 text-sm">{errors.cityArea}</p>} {/* Error message */}
                        </div>

                        <div>
                            <input
                                type="text"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                placeholder="Country"
                                className="w-full p-2 border"
                                ref={(el) => setInputRef('country', el)} // Register input reference
                            />
                            {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>} {/* Error message */}
                        </div>

                        <div>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Description"
                                className="w-full p-2 border"
                                ref={(el) => setInputRef('description', el)} // Register input reference
                            />
                            {errors.description && <p className="text-red-500 text-sm">{errors.description}</p>} {/* Error message */}
                        </div>

                        <label className="block font-semibold mt-4">Project Features</label>
                        <input type="number" name="totalLandArea" value={formData.projectFeatures.totalLandArea} onChange={(e) => handleChange(e, 'projectFeatures')} placeholder="Total Land Area" className="w-full p-2 border" />
                        <input
                            type="date"
                            name="completionDate"
                            value={formData.projectFeatures.completionDate || ""}
                            onChange={(e) => {
                                const { value } = e.target; // YYYY-MM-DD from input
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    projectFeatures: {
                                        ...prevFormData.projectFeatures,
                                        completionDate: value, // Store as is
                                    },
                                }));
                            }}
                            placeholder="Completion Date"
                            className="w-full p-2 border rounded"
                        />
                        <div>
                            <input
                                type="number"
                                name="unitsAvailable"
                                value={formData.projectFeatures.unitsAvailable}
                                onChange={(e) => handleChange(e, 'projectFeatures')}
                                placeholder="Units Available"
                                className={`w-full p-2 border ${errors['projectFeatures.unitsAvailable'] ? 'border-red-500' : ''}`}
                                ref={(el) => setInputRef('projectFeatures.unitsAvailable', el)}
                            />
                            {errors['projectFeatures.unitsAvailable'] && (
                                <p className="text-red-500 text-sm">{errors['projectFeatures.unitsAvailable']}</p>
                            )}
                        </div>

                        <div>
                            <input
                                type="number"
                                name="yearsOfLease"
                                value={formData.projectFeatures.yearsOfLease}
                                onChange={(e) => handleChange(e, 'projectFeatures')}
                                placeholder="Years of Lease"
                                className={`w-full p-2 border ${errors['projectFeatures.yearsOfLease'] ? 'border-red-500' : ''}`}
                                ref={(el) => setInputRef('projectFeatures.yearsOfLease', el)}
                            />
                            {errors['projectFeatures.yearsOfLease'] && (
                                <p className="text-red-500 text-sm">{errors['projectFeatures.yearsOfLease']}</p>
                            )}
                        </div>
                        <label className="block font-semibold mt-4">General Pictures</label>
                        {formData.generalPicturesUrls.map((picture, index) => (
                        <div key={index} className="flex flex-col space-y-2 mb-4">
                            {/* URL Input */}
                            <div className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    value={picture.url}
                                    onChange={(e) => {
                                        const updatedPictures = [...formData.generalPicturesUrls];
                                        updatedPictures[index].url = e.target.value;
                                        updatedPictures[index].file = null; // Clear file if a URL is provided
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            generalPicturesUrls: updatedPictures,
                                        }));
                                        localStorage.setItem(
                                            LOCAL_STORAGE_KEY,
                                            JSON.stringify({
                                                ...formData,
                                                generalPicturesUrls: updatedPictures,
                                            })
                                        );
                                    }}
                                    placeholder={`Picture URL ${index + 1}`}
                                    className="w-full p-2 border"
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        const updatedPictures = formData.generalPicturesUrls.filter((_, i) => i !== index);
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            generalPicturesUrls: updatedPictures,
                                        }));
                                        localStorage.setItem(
                                            LOCAL_STORAGE_KEY,
                                            JSON.stringify({
                                                ...formData,
                                                generalPicturesUrls: updatedPictures,
                                            })
                                        );
                                    }}
                                    className="text-red-500"
                                >
                                    Remove
                                </button>
                            </div>

                            {/* File Input */}
                            <div className="flex items-center space-x-2">
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            const updatedPictures = [...formData.generalPicturesUrls];
                                            updatedPictures[index].file = file;
                                            updatedPictures[index].url = ''; // Clear URL if a file is uploaded
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                generalPicturesUrls: updatedPictures,
                                            }));
                                            localStorage.setItem(
                                                LOCAL_STORAGE_KEY,
                                                JSON.stringify({
                                                    ...formData,
                                                    generalPicturesUrls: updatedPictures,
                                                })
                                            );
                                        }
                                    }}
                                    className="w-full p-2 border"
                                />
                                {picture.file && (
                                    <span className="text-gray-500 text-sm">{picture.file.name}</span>
                                )}
                            </div>
                        </div>
                    ))}

                    {/* Add New Picture Button */}
                    <button
                        type="button"
                        onClick={() => {
                            setFormData((prevFormData) => ({
                                ...prevFormData,
                                generalPicturesUrls: [
                                    ...prevFormData.generalPicturesUrls,
                                    { url: '', file: null }, // Add an empty object for new entry
                                ],
                            }));
                            localStorage.setItem(
                                LOCAL_STORAGE_KEY,
                                JSON.stringify({
                                    ...formData,
                                    generalPicturesUrls: [
                                        ...formData.generalPicturesUrls,
                                        { url: '', file: null },
                                    ],
                                })
                            );
                        }}
                        className="text-blue-500"
                    >
                        Add Picture
                    </button>

                        <label className="block font-semibold mt-4">Shared Amenities</label>
                        <div className="mb-4">
                            {availableAmenities
                                .filter(amenity => amenity.type === 'shared')
                                .map(amenity => (
                                    <div key={amenity._id} className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            name={amenity.name}
                                            checked={formData.amenities.sharedAmenities[amenity.name] || false}
                                            onChange={(e) => handleChange(e, 'sharedAmenities')}
                                        />
                                        <label>{amenity.name}</label>
                                    </div>
                                ))}
                        </div>

                        <label className="block font-semibold mt-4">Investment Information</label>
                        <div>
                            <input
                                type="number"
                                name="minROI"
                                value={formData.investmentInformation.minROI}
                                onChange={(e) => handleChange(e, 'investmentInformation')}
                                placeholder="Minimum ROI"
                                min="0"
                                step="0.01"
                                className={`w-full p-2 border ${errors['investmentInformation.minROI'] ? 'border-red-500' : ''}`}
                                ref={(el) => setInputRef('investmentInformation.minROI', el)}
                            />
                            {errors['investmentInformation.minROI'] && (
                                <p className="text-red-500 text-sm">{errors['investmentInformation.minROI']}</p>
                            )}
                        </div>

                        <div>
                        <input
                            type="number"
                            name="maxROI"
                            value={formData.investmentInformation.maxROI}
                            onChange={(e) => handleChange(e, 'investmentInformation')}
                            placeholder="Maximum ROI"
                            className="w-full p-2 border"
                            ref={(el) => setInputRef('investmentInformation.maxROI', el)} // Top-level ref
                        />
                            {errors['investmentInformation.maxROI'] && (
                                <p className="text-red-500 text-sm">{errors['investmentInformation.maxROI']}</p>
                            )}
                        </div>

                        <label className="block font-semibold mt-4">Construction Details</label>
                        <input
                            type="number"
                            name="constructionProgress"
                            value={formData.constructionDetails.constructionProgress}
                            onChange={(e) => handleChange(e, 'constructionDetails')}
                            placeholder="Construction Progress (%)"
                            className="w-full p-2 border"
                        />
                        <label className="block font-semibold mt-4">Construction Progress Details</label>
                        <select
                            name="constructionProgressDetails"
                            value={formData.constructionDetails.constructionProgressDetails} // Default to 'Project Planning'
                            onChange={(e) => handleChange(e, 'constructionDetails')}
                            className="w-full p-2 border rounded-md"
                        >
                            <option value="" disabled>Select Progress Details</option>
                            <option value="Project Planning">Project Planning</option>
                            <option value="Under Construction">Under Construction</option>
                            <option value="Completed">Completed</option>
                        </select>
                        <input
                            type="number"
                            name="warrantyPeriod"
                            value={formData.constructionDetails.warrantyPeriod}
                            onChange={(e) => handleChange(e, 'constructionDetails')}
                            placeholder="Warranty Period (years)"
                            className="w-full p-2 border"
                        />

                        <input
                            type="text"
                            name="constructionMaterials"
                            value={formData.constructionDetails.constructionMaterials}
                            onChange={(e) => handleChange(e, 'constructionDetails')}
                            placeholder="Construction Materials"
                            className="w-full p-2 border"
                        />
                        <div className="mt-4">
                            <label className="block font-semibold">Presentation PDF URL</label>
                            <input
                                type="url"
                                name="presentationPdfUrl"
                                value={formData.presentationPdfUrl}
                                onChange={handleChange}
                                placeholder="Presentation PDF URL"
                                className={`w-full p-2 border ${errors.presentationPdfUrl ? 'border-red-500' : ''}`}
                                ref={(el) => setInputRef('presentationPdfUrl', el)} // Register the input reference
                            />
                            {errors.presentationPdfUrl && (
                                <p className="text-red-500 text-sm mt-1">{errors.presentationPdfUrl}</p>
                            )}
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold">Financial Model URL</label>
                            <input
                                type="url"
                                name="financialModelUrl"
                                value={formData.financialModelUrl}
                                onChange={handleChange}
                                placeholder="Financial Model URL"
                                className={`w-full p-2 border ${errors.financialModelUrl ? 'border-red-500' : ''}`}
                                ref={(el) => setInputRef('financialModelUrl', el)} // Register the input reference
                            />
                            {errors.financialModelUrl && (
                                <p className="text-red-500 text-sm mt-1">{errors.financialModelUrl}</p>
                            )}
                        </div>
                    </>
                    )}

                    {activeTab === 'units' && (
                        <>
                        {/* Units */}
                        <label className="block font-semibold mt-4">Units</label>
                        {formData.projectFeatures.units.map((unit, unitIndex) => (
                        <div key={unitIndex} className="border p-4 mb-4">
                            <h3 className="font-semibold">Unit {unitIndex + 1}</h3>
                            <input
                            type="text"
                            name="unitName"
                            value={unit.unitName}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Unit Name Type (apartment, private villa, townhouse, etc.)"
                            className="w-full p-2 border mb-2"
                            />
                            <textarea
                            name="description"
                            value={unit.description}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Unit Description"
                            className="w-full p-2 border mb-2"
                            />
                           <input
                                type="number"
                                name="price"
                                value={unit.price}
                                onChange={(e) => handleUnitChange(e, unitIndex)}
                                placeholder="Price"
                                className="w-full p-2 border mb-2"
                                ref={(el) => setNestedInputRef(unitIndex, 'price', el)} // Unit-specific ref
                            />
                            {errors[`projectFeatures.units[${unitIndex}].price`] && (
                                <p className="text-red-500 text-sm">
                                    {errors[`projectFeatures.units[${unitIndex}].price`]}
                                </p>
                            )}
                            <input
                            type="text"
                            name="bedrooms"
                            value={unit.bedrooms}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Bedrooms"
                            className="w-full p-2 border mb-2"
                            />
                            <input
                            type="text"
                            name="buildingArea"
                            value={unit.buildingArea}
                            onChange={(e) => handleUnitChange(e, unitIndex)}
                            placeholder="Area in m²"
                            className="w-full p-2 border mb-2"
                            />
                            <div className="mb-2">
                                <label className="block font-semibold">Units Available (# of units with same price, amenities, sales status)</label>
                                <input
                                    type="number"
                                    name="unitsAvailable"
                                    value={unit.unitsAvailable}
                                    onChange={(e) => handleUnitChange(e, unitIndex)}
                                    placeholder="1"
                                    className={`w-full p-2 border ${errors[`projectFeatures.units[${unitIndex}].unitsAvailable`] ? 'border-red-500' : ''}`}
                                    ref={(el) => setInputRef(`projectFeatures.units[${unitIndex}].unitsAvailable`, el)} // Register input reference
                                />
                                {errors[`projectFeatures.units[${unitIndex}].unitsAvailable`] && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors[`projectFeatures.units[${unitIndex}].unitsAvailable`]}
                                    </p>
                                )}
                            </div>
                        <label className="block font-semibold mt-4">Sales Status</label>
                        <select
                            name="salesDetails"
                            value={unit.salesDetails?.status || ''}
                            onChange={(e) => handleChange(e, 'salesDetails', unitIndex)}
                            className="w-full p-2 border rounded-md"
                        >
                            <option value="" disabled>Select Sales Status</option>
                            <option value="For Sale">For Sale</option>
                            <option value="Sold">Sold</option>
                            <option value="Presale">Presale</option>
                            <option value="Booked">Booked</option>
                        </select>

                            {/* Unit Pictures */}
                            <label className="block font-semibold mt-4">Unit Pictures</label>
                                    {unit.pictures.map((picture, pictureIndex) => (
                                        <div key={pictureIndex} className="flex flex-col space-y-2 mb-4">
                                            <div className="flex items-center space-x-2">
                                                {/* URL Input */}
                                                <input
                                                    type="text"
                                                    value={picture.url}
                                                    onChange={(e) =>
                                                        handleUnitPictureChange(
                                                            unitIndex,
                                                            pictureIndex,
                                                            'url',
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder={`Picture URL ${pictureIndex + 1}`}
                                                    className="w-full p-2 border"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        removeUnitPictureField(unitIndex, pictureIndex)
                                                    }
                                                    className="text-red-500"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                {/* File Input */}
                                                <input
                                                    type="file"
                                                    onChange={(e) => {
                                                        const file = e.target.files?.[0];
                                                        if (file) {
                                                            handleUnitPictureChange(
                                                                unitIndex,
                                                                pictureIndex,
                                                                'file',
                                                                file
                                                            );
                                                        }
                                                    }}
                                                    className="w-full p-2 border"
                                                />
                                                {picture.file && (
                                                    <span className="text-gray-500 text-sm">
                                                        {picture.file.name}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={() => addUnitPictureField(unitIndex)}
                                        className="text-blue-500"
                                    >
                                        Add Picture
                                    </button>

                            {/* Unit Amenities */}
                            <label className="block font-semibold mt-4">Amenities</label>
                            <div className="grid grid-cols-2 gap-4">

                            {/* Private Amenities */}
                            <div>
                                {availableAmenities
                                .filter((amenity) => amenity.type === 'private')
                                .map((amenity) => (
                                    <div key={amenity._id} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={unit.amenities.privateAmenities[amenity.name] || false}
                                        onChange={(e) =>
                                        setFormData((prevFormData) => {
                                            const updatedUnits = prevFormData.projectFeatures.units.map((u, idx) =>
                                            idx === unitIndex
                                                ? {
                                                    ...u,
                                                    amenities: {
                                                    ...u.amenities,
                                                    privateAmenities: {
                                                        ...u.amenities.privateAmenities,
                                                        [amenity.name]: e.target.checked,
                                                    },
                                                    },
                                                }
                                                : u
                                            );
                                            return {
                                            ...prevFormData,
                                            projectFeatures: {
                                                ...prevFormData.projectFeatures,
                                                units: updatedUnits,
                                            },
                                            };
                                        })
                                        }
                                    />
                                    <label>{amenity.name}</label>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => applyAmenitiesToAllUnits(unitIndex)}
                                    className="text-blue-500 mt-2"
                                >
                                    Apply Amenities to other units
                                </button>
                            </div>
                            </div>

                            {unitIndex !== 0 && (
                                <button
                                    type="button"
                                    onClick={() => removeUnit(unitIndex)}
                                    className="text-red-500 mt-2"
                                >
                                    Remove Unit
                                </button>
                            )}
                        </div>
                        ))}
                        <button type="button" onClick={addUnit} className="text-blue-500">Add Unit</button>
                    </>
                    )}

                    <button type="submit" className="w-full py-3 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300">
                        {identifier ? 'Update Project' : 'Add Project'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddOrEditProject;