import React from 'react';
import moment from 'moment-timezone';

interface TimeZoneSelectorProps {
  selectedTimeZone: string;
  onChange: (selectedTimeZone: string) => void;
}

const TimeZoneSelector: React.FC<TimeZoneSelectorProps> = ({ selectedTimeZone, onChange }) => {
  const timeZones = moment.tz.names();

  // Get GMT offset for a time zone
  const getGMTOffset = (zone: string) => {
    const offset = moment.tz(zone).utcOffset() / 60; // Offset in hours
    const sign = offset >= 0 ? '+' : '-';
    return `GMT${sign}${Math.abs(offset).toString().padStart(2, '0')}:00`;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedZone = event.target.value;
    onChange(selectedZone); // Notify the parent component
  };

  return (
    <div>
      <label htmlFor="timezone" className="block text-gray-700 font-medium mb-2">
        Your Time Zone
      </label>
      <select
        id="timezone"
        className="border border-gray-300 rounded-md p-2 w-full"
        value={selectedTimeZone}
        onChange={handleChange}
      >
        {timeZones.map((zone) => (
          <option key={zone} value={zone}>
            {zone} ({getGMTOffset(zone)})
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeZoneSelector;
