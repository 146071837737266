import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropertyCard from '../components/PropertyCard';
import DropDown from '../components/Dropdown';
import Loader from '../components/Loader';
import useCity from '../hooks/useCity';
import useProject from '../hooks/useProject';
import { Project } from '../models/Project';
import { City } from '../models/City';
import ListingDetails from '../components/ListingDetails';
import Logo from '../../src/BektuAI.svg';
import useLogin from '../hooks/useLogin';
import SlideInMenu from '../components/Menu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";


const Home: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showLoginFooter, setShowLoginFooter] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { login, loading, error } = useLogin();
  const [menuOpen, setMenuOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loaderDivRef = useRef<HTMLDivElement | null>(null);


  const navigate = useNavigate();
  const { identifier } = useParams<{ identifier: string }>();
  const location = useLocation();

  // Toggle scroll lock on modal visibility
  useEffect(() => {
    document.body.style.overflow = isModalVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto'; // Reset when component unmounts
    };
  }, [isModalVisible]);

  // Fetch cities on mount
  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setPage(1);
      setProjects([]);
      fetchProjects(selectedCity, 1, true);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (page > 1 && selectedCity) {
      fetchProjects(selectedCity, page, false);
    }
  }, [page]);

  // Check for a project ID in the URL and open the modal if needed
  useEffect(() => {
    if (identifier) {
      const project = projects.find((p) => p.identifier === identifier);
      if (project) {
        setSelectedProject(project);
        setModalVisible(true);
      } else {
        fetchProjectDetails(identifier);
      }
    }
  }, [identifier, projects]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        setPage((prevPage) => prevPage + 1);
      }
    });
  
    const loaderDiv = loaderDivRef.current;
    if (loaderDiv) observer.observe(loaderDiv);
  
    return () => {
      if (loaderDiv) observer.unobserve(loaderDiv);
    };
  }, [hasMore, loadingMore]);

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const fetchProjectDetails = async (projectId: string) => {
    try {
      const response = await useProject.getDetails(projectId);
      setSelectedProject(response.data as Project);
      setModalVisible(true);
    } catch (error) {
      console.error('Failed to fetch project details:', error);
      navigate('/'); // Redirect to home if the project is not found
    }
  };

  const fetchProjects = async (cityName: string, page: number = 1, isRefreshing: boolean = false) => {
    try {
      if (isRefreshing) setRefreshing(true);
      else setLoadingMore(true);
  
      const response = await useProject.getProjects(cityName, page);
      if (Array.isArray(response.projects)) {
        setProjects((prevProjects) =>
          page === 1 ? response.projects : [...prevProjects, ...response.projects]
        );
        if (response.projects.length < 5) setHasMore(false);
      } else {
        console.error('Projects is not an array:', response.projects);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setRefreshing(false);
      setLoadingMore(false);
    }
  };

  const fetchCities = useCallback(async () => {
    try {
      const fetchedCities = await useCity.getCities();
      setCities(fetchedCities);
      if (fetchedCities.length > 0) {
        setSelectedCity(fetchedCities[0].name);
      }
    } catch (error) {
      console.error('Failed to fetch cities:', error);
    }
  }, []);

  const handleSelectCity = (cityName: string) => {
    setSelectedCity(cityName);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePropertyClick = (project: Project) => {
    setSelectedProject(project); // Set the selected project for the modal
    setModalVisible(true);       // Ensure the modal is visible
    navigate(`/project/${project.identifier}`); // Update the URL
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
    setModalVisible(false);
    if (location.pathname.includes('/project/')) {
      navigate('/'); // Remove the project ID from the URL
    }
  };

  const handleLoginClick = () => {
    setShowLoginFooter(true);
  };

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setSuccessMessage('');
    } else {
      setEmailError('');
      const result = await login(email);
      if (result) {
        setSuccessMessage('Check your email for the magic link!');
        setEmailError('');
      } else {
        setSuccessMessage('');
      }
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
<div className="min-h-screen bg-white p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 pt-24">
  {/* Header Section */}
  <div className="fixed top-0 left-0 w-full bg-white border-b border-gray-200 z-10 flex items-center justify-between px-4 sm:px-6 lg:px-8 h-16">
    {isModalVisible && selectedProject && (
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal-background ${
          isModalVisible ? "open" : ""
        }`}
      >
        <ListingDetails project={selectedProject} onClose={handleCloseModal} />
      </div>
    )}

    {/* Left Section: Logo and Text */}
    <div className="flex items-center">
      {/* Logo */}
      <div className="text-2xl font-bold text-gray-800 relative">
        Bektu
        <span className="absolute -top-2 -right-6 text-[10px] font-normal text-gray-400">
          Beta
        </span>
      </div>

      {/* City Dropdown */}
      <div className="ml-8 hidden sm:flex">
        <DropDown
          cities={cities}
          selectedCity={selectedCity}
          onSelectCity={handleSelectCity}
        />
      </div>
    </div>

    {/* Right Section: Hamburger Menu */}
    <div
      className="cursor-pointer z-30 flex items-center sm:hidden"
      onClick={toggleMenu}
    >
      <FontAwesomeIcon
        icon={menuOpen ? faTimes : faBars}
        className="text-xl text-gray-800"
      />
    </div>

    {/* SlideInMenu Component */}
    <SlideInMenu isOpen={menuOpen} onClose={toggleMenu} />
  </div>

      {/* Main Content */}
      {refreshing && page === 1 ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div
          className={`transition-opacity duration-250 ${
            isModalVisible ? 'opacity-0' : 'opacity-100'
          }`}
        >
          {/* Add margin-top to avoid overlap */}
          <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6 md:mt-32 md:mt-36"
            style={{
              gridAutoRows: 'minmax(250px, auto)', // Sets dynamic heights for cards
            }}
          >
            {projects.map((project) => (
              <PropertyCard
                key={project.id}
                project={project}
                onClick={() => handlePropertyClick(project)}
                onLoginClick={handleLoginClick}
                isAdmin={false}
              />
            ))}
          </div>
        </div>
      )}

      {/* Infinite Scroll Loader */}
      {hasMore && (
        <div ref={loaderDivRef} className="flex justify-center items-center h-20">
          {loadingMore && <Loader />}
        </div>
      )}

      {/* Footer Section */}
      {showLoginFooter && (
        <div className="w-full fixed bottom-0 left-0 bg-white p-6 shadow-lg">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full md:w-1/3 border border-gray-300 p-2 rounded-lg"
            />
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            {successMessage && (
              <p className="text-green-500 text-sm">{successMessage}</p>
            )}
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              className={`w-full md:w-auto bg-blue-500 text-white py-3 px-6 rounded-lg ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleEmailSubmit}
              disabled={loading}
            >
              <span>{loading ? 'Submitting...' : 'Explore property insights'}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );  
};

export default Home;
