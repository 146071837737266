import React, { useState } from "react";
import usePdfUpload from "../hooks/usePdfUpload";

interface PdfUploaderProps {
  onProcessComplete: (data: any) => void;
  setLoading: (loading: boolean) => void; // Pass a function to update the loading state
}

const PdfUploader: React.FC<PdfUploaderProps> = ({ onProcessComplete, setLoading }) => {
  const { uploadPdf, uploading, error } = usePdfUpload();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      // Automatically trigger upload
      setLoading(true); // Start loading
      uploadPdf(
        selectedFile,
        (result) => {
          onProcessComplete(result);
          setLoading(false); // Stop loading on success
        },
        (uploadError) => {
          console.error("Upload failed:", uploadError);
          setLoading(false); // Stop loading on error
        }
      );
    }
  };

  return (
    <div className="mb-6">
      <label className="block text-gray-700">Upload Presentation</label>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileChange}
        className="block w-full border p-2"
        disabled={uploading} // Prevent file selection while uploading
      />
      {uploading && <p className="text-blue-500 mt-2">Uploading and processing...</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default PdfUploader;