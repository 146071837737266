import React, { useEffect, useState } from 'react';
import apiClient from '../api/apiClientInterceptor';
import { Developer } from '../models/Project';

interface DeveloperDropdownProps {
  selectedDeveloper: string;
  onSelect: (developerName: string) => void; // Pass only the name
}

const DeveloperDropdown: React.FC<DeveloperDropdownProps> = ({ selectedDeveloper, onSelect }) => {
  const [developers, setDevelopers] = useState<Developer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDevelopers = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get('user/getCurrentDevelopers');
        setDevelopers(response.data);
      } catch (err) {
        console.error('Failed to fetch developers:', err);
        setError('Failed to load developers. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDevelopers();
  }, []);

  if (loading) return <p>Loading developers...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="mb-4">
      <label className="block font-semibold mb-2">Select Developer</label>
      <select
        className="w-full p-2 border rounded-md"
        value={selectedDeveloper}
        onChange={(e) => onSelect(e.target.value)}
      >
        <option value="" disabled>
          Choose a developer
        </option>
        {developers.map((developer) => (
          <option key={developer.name} value={developer.name}>
            {developer.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DeveloperDropdown;
