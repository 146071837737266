import React, { useState } from 'react';
import moment from 'moment-timezone';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Booking } from '../models/Booking'; // Adjust the path
import TimeZoneSelector from './TimeZoneSelector'; // Adjust the path
import { Project } from '../models/Project';
import apiClient from '../api/apiClientInterceptor';

interface FormModalProps {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
  onSubmit: (data: Booking & { selectedUnit: string }) => void; // Adjusted type to include selectedUnit
}

const BookingModal: React.FC<FormModalProps> = ({ isOpen, project, onClose, onSubmit }) => {
    console.log(project)
  const [formData, setFormData] = useState<Booking>({
    name: '',
    contactMethod: 'email',
    email: '',
    phoneNumber: '',
    timeZone: 'UTC',
    date: moment().add(1, 'days').format('YYYY-MM-DD'),
    times: [],
    selectedUnit: ''
  });

  const [selectedUnit, setSelectedUnit] = useState<string>(
    project.projectFeatures.units?.[0]?.identifier || '' // Default to the first unit's identifier
  ); // Default to the first unit, if available

  const availableDates = Array.from({ length: 7 }, (_, i) =>
    moment().add(i + 1, 'days').format('YYYY-MM-DD')
  );

  const generateAvailableTimes = (start: number, end: number): string[] => {
    const times: string[] = [];
    for (let hour = start; hour < end; hour++) {
      const startTime = `${hour.toString().padStart(2, '0')}:00`;
      const endTime = `${(hour + 1).toString().padStart(2, '0')}:00`;
      times.push(`${startTime}-${endTime}`);
    }
    return times;
  };

  const availableTimes = generateAvailableTimes(8, 19);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value: string) => {
    // Ensure the value always starts with '+'
    if (!value.startsWith('+')) {
      value = `+${value}`;
    }
    setFormData((prevData) => ({ ...prevData, phoneNumber: value }));
  };

  const handleTimesChange = (time: string) => {
    setFormData((prevData) => {
      const newTimes = prevData.times.includes(time)
        ? prevData.times.filter((t) => t !== time)
        : prevData.times.length < 3
        ? [...prevData.times, time]
        : prevData.times;
      return { ...prevData, times: newTimes };
    });
  };

  const handleContactMethodChange = (method: 'email' | 'phone') => {
    setFormData((prevData) => ({
      ...prevData,
      contactMethod: method,
      email: method === 'email' ? prevData.email : '',
      phoneNumber: method === 'phone' ? prevData.phoneNumber : '',
    }));
  };

  const handleTimeZoneChange = (selectedTimeZone: string) => {
    setFormData((prevData) => ({
      ...prevData,
      timeZone: selectedTimeZone,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
        formData.contactMethod === 'phone' &&
        (!formData.phoneNumber || !formData.phoneNumber.startsWith('+'))
      ) {
        alert('Please include a valid phone number with the country code.');
        return;
    }

    const bookingData = {
        ...formData,
        selectedUnit,
    };

    try {
        const response = await apiClient.post('/booking/request', bookingData);
        if (response) {
            console.log('Booking request successful:', response);
            onClose(); 
        }
      } catch (error) {
        console.error('Error submitting booking request:', error);
        alert('Failed to submit booking request. Please try again.');
      }
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center transition-transform duration-300 ${
        isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
      }`}
    >
      <div className="bg-white w-full max-w-md h-full max-h-[90%] p-6 rounded-lg shadow-lg overflow-y-auto">
        <div className="flex justify-between items-center mb-4 sticky top-0 bg-white z-10">
          <h2 className="text-xl font-semibold">Request a call</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Name Input */}
          <div className="mb-4">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Your name"
              required
            />
          </div>

          {/* Project Unit Selector */}
        <div className="mb-4">
        <label htmlFor="unit" className="block text-gray-700 font-medium mb-2">
            Which unit are you interested in?
        </label>
        <select
            id="unit"
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            className="border border-gray-300 rounded-md p-2 w-full"
            required
        >
            {project.projectFeatures.units.map((unit) => (
            <option key={unit.identifier} value={unit.identifier}>
                {unit.unitName} {/* Show unit name in dropdown */}
            </option>
            ))}
        </select>
        </div>

          {/* Contact Method as Buttons */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Contact Method</label>
            <div className="flex gap-4">
              <button
                type="button"
                className={`px-4 py-2 rounded-md text-white ${
                  formData.contactMethod === 'email'
                    ? 'bg-blue-500'
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                onClick={() => handleContactMethodChange('email')}
              >
                Email
              </button>
              <button
                type="button"
                className={`px-4 py-2 rounded-md text-white ${
                  formData.contactMethod === 'phone'
                    ? 'bg-blue-500'
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
                onClick={() => handleContactMethodChange('phone')}
              >
                Phone
              </button>
            </div>
          </div>

          {/* Email Input */}
          {formData.contactMethod === 'email' && (
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 w-full"
                placeholder="Enter your email"
                required
              />
            </div>
          )}

          {/* Phone Number Input */}
          {formData.contactMethod === 'phone' && (
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block text-gray-700 font-medium mb-2">
                Phone Number
              </label>
              <PhoneInput
                country="us"
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  name: 'phoneNumber',
                  required: true,
                }}
                containerStyle={{ width: '100%' }}
              />
            </div>
          )}

          {/* Timezone Selector */}
          <div className="mb-4">
            <TimeZoneSelector
              onChange={handleTimeZoneChange}
              selectedTimeZone={formData.timeZone}
            />
          </div>

          {/* Date Selector */}
          <div className="mb-4">
            <label htmlFor="date" className="block text-gray-700 font-medium mb-2">
              When is the best day for you?
            </label>
            <select
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="border border-gray-300 rounded-md p-2 w-full"
            >
              {availableDates.map((date) => (
                <option key={date} value={date}>
                  {moment(date).format('dddd, MMMM Do YYYY')}
                </option>
              ))}
            </select>
          </div>

          {/* Time Slots */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              Select Available Times (up to 3)
            </label>
            <div className="grid grid-cols-2 gap-2">
              {availableTimes.map((time) => (
                <button
                  key={time}
                  type="button"
                  className={`border border-gray-300 rounded-md p-2 text-center ${
                    formData.times.includes(time) ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
                  }`}
                  onClick={() => handleTimesChange(time)}
                >
                  {time}
                </button>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;
