import React, { useState } from 'react';
import useLogin from '../hooks/useLogin';
import loginImage from '../../src/chair.jpg';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { login, loading, error } = useLogin();

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setSuccessMessage(''); // Clear success message if there's an error
    } else {
      setEmailError(''); // Clear error

      const result = await login(email); // Call the custom hook to make the API request
      if (result) {
        setSuccessMessage('Check your email for the magic link!'); // Set success message
        setEmailError(''); // Clear any email error if it exists
      } else {
        setSuccessMessage(''); // Clear success message if there's no result
      }
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="flex flex-col lg:flex-row items-center min-h-screen">
      {/* Left Side - Image */}
      <div className="hidden lg:flex flex-1 h-screen">
        <img
          src={loginImage}
          alt="Login Visual"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Right Side - Login Form */}
      <div className="flex-1 flex items-center justify-center w-full p-8">
        <div className="w-full max-w-lg bg-white rounded-lg p-8">
          {/* Conditionally Render Form or Success Message */}
          {!successMessage ? (
            <>
              {/* Heading */}
              <h1 className="text-3xl font-bold text-gray-900 text-center mb-6">
                Welcome Back
              </h1>
              <p className="text-gray-600 text-center mb-6">
                Enter your email to login to your account
              </p>

              {/* Email Input */}
              <div className="space-y-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                {/* Error Message */}
                {emailError && (
                  <p className="text-red-500 text-sm">{emailError}</p>
                )}

                {/* Error from Login */}
                {error && <p className="text-red-500 text-sm">{error}</p>}

                {/* Submit Button */}
                <button
                  className={`w-full bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-500 transition-all duration-300 ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  onClick={handleEmailSubmit}
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Login'}
                </button>
              </div>
            </>
          ) : (
            /* Success Message */
            <div className="text-center">
              <h1 className="text-3xl font-bold text-gray-400 mb-4">
                Please check your email. You'll receive a link.
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
