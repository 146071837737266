import React, { useState } from 'react';
import apiClient from '../api/apiClientInterceptor';

const RegisterDeveloper = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    logo: ''
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true); // Show loader

    try {
      // Replace with your backend endpoint
      const response = await apiClient.post('developer/create', formData);

      setSuccessMessage('Developer registered successfully!');
      setFormData({ name: '', email: '', logo: '' });
      setTimeout(() => setSuccessMessage(''), 3000); // Dismiss success message
    } catch (error: any) {
      setError(
        error.response?.data?.error || 'An error occurred while registering the developer.'
      );
      setTimeout(() => setError(''), 3000); // Dismiss error message
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className="max-w-xl mx-auto p-4 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-semibold text-center mb-4">Register Developer</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          placeholder="Developer Name"
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Developer Email (Optional)"
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="text"
          name="logo"
          value={formData.logo}
          onChange={handleChange}
          placeholder="Logo URL (Optional)"
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          type="submit"
          className={`w-full py-3 px-4 font-semibold rounded-md transition duration-300 ${
            isLoading
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? 'Submitting...' : 'Register Developer'}
        </button>
      </form>
    </div>
  );
};

export default RegisterDeveloper;