import React, { useState } from 'react';

interface ImageCarouselProps {
  pictures: string[];
  blurAmount?: number; // Optional blur amount prop to control image blur
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ pictures, blurAmount = 0 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? pictures.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === pictures.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Handle touch/mouse start
  const handleStart = (clientX: number) => {
    setStartX(clientX);
    setIsDragging(true);
  };

  // Handle touch/mouse move
  const handleMove = (clientX: number) => {
    if (!isDragging || startX === null) return;

    const diff = startX - clientX;

    // Only move to the next/previous slide if the drag exceeds a threshold
    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        goToNext(); // Swipe/drag left
      } else {
        goToPrevious(); // Swipe/drag right
      }

      setIsDragging(false); // Stop dragging to prevent multiple updates
      setStartX(clientX); // Reset start position
    }
  };

  // Handle touch/mouse end
  const handleEnd = () => {
    setIsDragging(false);
    setStartX(null); // Reset swipe state
  };

  // Dots logic
  const renderDots = () => {
    if (pictures.length <= 1) return null; // No dots for single image

    if (pictures.length <= 3) {
      // Render all dots if there are 3 or fewer pictures
      return pictures.map((_, idx) => (
        <button
          key={idx}
          onClick={() => goToSlide(idx)}
          className={`w-3 h-3 rounded-full ${
            currentIndex === idx ? 'bg-white' : 'bg-gray-400'
          }`}
        />
      ));
    }

    // For more than 3 pictures, render max 3 dots
    return (
      <>
        <button
          onClick={() => goToSlide(0)} // First picture
          className={`w-3 h-3 rounded-full ${
            currentIndex === 0 ? 'bg-white' : 'bg-gray-400'
          }`}
        />
        <button
          onClick={() => goToSlide(currentIndex)} // Current picture
          className={`w-3 h-3 rounded-full bg-white`}
        />
        <button
          onClick={() => goToSlide(pictures.length - 1)} // Last picture
          className={`w-3 h-3 rounded-full ${
            currentIndex === pictures.length - 1 ? 'bg-white' : 'bg-gray-400'
          }`}
        />
      </>
    );
  };

  return (
    <div
      className="relative w-full h-64 md:h-80 lg:h-96"
      onTouchStart={(e) => handleStart(e.touches[0].clientX)}
      onTouchMove={(e) => handleMove(e.touches[0].clientX)}
      onTouchEnd={handleEnd}
      onMouseDown={(e) => {
        e.preventDefault(); // Prevent default drag behavior
        handleStart(e.clientX);
      }}
      onMouseMove={(e) => {
        if (isDragging) handleMove(e.clientX);
      }}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
    >
      {/* Carousel Images */}
      <div className="overflow-hidden w-full h-full">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {pictures.map((picture, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <img
                src={picture}
                alt={`Image ${index + 1}`}
                className="w-full h-64 md:h-80 lg:h-96 object-cover"
                draggable="false" // Disable image dragging
                style={{
                  filter: `blur(${blurAmount}px)`,
                  transition: 'filter 0.3s ease',
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Dots Navigation */}
      <div className="absolute bottom-2 left-0 right-0 flex justify-center space-x-2">
        {renderDots()}
      </div>
    </div>
  );
};

export default ImageCarousel;
