import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Page';
import DesktopOverlay from './components/DesktopOverlay';
import AuthPage from './pages/Auth';
import AddRealEstateListing from './pages/AddRealEstateListing';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './utils/ProtectedRoute';
import AdminPage from './pages/Admin';
import AddOrEditProject from './pages/AddOrEditProject';
import ListingDetails from './components/ListingDetails';
import RegisterDeveloper from './pages/RegisterDeveloper';

const App: React.FC = () => {
  const location = useLocation(); // Get the current route

  return (
    <>
      {/* Conditionally render DesktopOverlay if the current route is not '/addRealEstate'
      {location.pathname !== '/addrealestate' && <DesktopOverlay />} */}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/:identifier" element={<Home />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/addrealestate"
          element={
            <ProtectedRoute>
              <AddRealEstateListing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-project"
          element={
            <ProtectedRoute>
              <AddOrEditProject />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-projects"
          element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-developer"
          element={
            <ProtectedRoute>
              <RegisterDeveloper />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editproject/:identifier"
          element={
            <ProtectedRoute>
              <AddOrEditProject />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

const AppWrapper: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;