import React, { useState, useRef, useEffect } from "react";
import { FaCopy, FaCheck } from "react-icons/fa";
import Loader from "./Loader";
import { Project } from "../models/Project";
import ImageCarousel from "./ImageCarousel";
import BookingModal from "./BookingModal";
import { formatDate } from "../utils/FormatDate";

interface ListingDetailsProps {
  project: Project;
  onClose: () => void;
}

const ListingDetails: React.FC<ListingDetailsProps> = ({ project, onClose }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [isMobile, setMobile] = useState(false)
  const [carouselHeight, setCarouselHeight] = useState(400); // Initial height
  const [initialHeight, setInitialHeight] = useState(400);
  const [blurAmount, setBlurAmount] = useState(0); // Initial blur
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOpenBookingModal = () => {
    setIsBookingModalOpen(true);
  };

  const handleCloseBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const handleFormSubmit = (data: { timeZone: string; name: string }) => {
    console.log('Form submitted:', data);
  };

  const updateCarouselHeight = () => {
    const isMobile = window.innerWidth <= 768; // Determine mobile breakpoint
    const newHeight = isMobile ? 260 : 400; // Set 260px for mobile, 400px for desktop
    setCarouselHeight(newHeight);
    setInitialHeight(newHeight); // Update the initial height
  };

  useEffect(() => {
    // Initial setup
    updateCarouselHeight();

    // Listen for resize events to update height
    const handleResize = () => {
      updateCarouselHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = modalRef.current?.scrollTop || 0;
      const maxBlur = 10; // Maximum blur effect
      const maxHeightReduction = initialHeight - 200; // Maximum height reduction to 200px

      // Adjust height and blur
      const newHeight = Math.max(initialHeight - scrollTop, 200); // Minimum height 200px
      setCarouselHeight(newHeight);

      // Reset height and blur if scrolling up
      if (scrollTop === 0) {
        setCarouselHeight(initialHeight);
        setBlurAmount(0);
      } else {
        const newBlur = Math.min(scrollTop / 20, maxBlur); // Gradual blur effect
        setBlurAmount(newBlur);
      }
    };

    const modal = modalRef.current;
    modal?.addEventListener("scroll", handleScroll);

    return () => {
      modal?.removeEventListener("scroll", handleScroll);
    };
  }, [initialHeight]);

  if (!project) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <Loader />
      </div>
    );
  }

  const handleCopyLink = () => {
    const shareableLink = `https://bektu.com/project/${project.identifier}`;
    navigator.clipboard.writeText(shareableLink).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 2000);
    });
  };

  const pictures = 
  (project.generalPicturesUrls && project.generalPicturesUrls.length > 0) 
    ? project.generalPicturesUrls 
    : project.projectFeatures.units?.[0]?.pictures || [];

  const units = project.projectFeatures.units || [];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal Container */}
      <div
        ref={modalRef}
        className="bg-white text-gray-900 rounded-lg shadow-lg overflow-y-auto"
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "1200px",
          maxHeight: isMobile ? "100vh" : "90vh",
          overscrollBehavior: "contain", // Prevent scroll chaining
        }}
      >
        {/* Header Section */}
        <div
          className="relative w-full overflow-hidden"
          style={{
            height: `${carouselHeight}px`,
            filter: `blur(${blurAmount}px)`,
            transition: "height 0.3s ease, filter 0.3s ease",
          }}
        >
          {/* Images Section */}
          <div className="sm:p-4">
            {/* Slider for Mobile */}
            <div className="lg:hidden">
              <ImageCarousel pictures={pictures} />
            </div>
  
            {/* Grid for Desktop */}
            <div className="hidden lg:grid grid-cols-4 gap-4 relative">
              {/* Large Image on the Left */}
              <div className="col-span-2 relative w-full h-96 bg-gray-200 rounded-lg overflow-hidden">
                {pictures[0] && (
                  <img
                    src={pictures[0]}
                    alt="Main Project Image"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
  
              {/* Smaller Images on the Right */}
              <div className="col-span-2 grid grid-cols-2 grid-rows-2 gap-4">
                {pictures.slice(1, 5).map((picture, index) => (
                  <div
                    key={index}
                    className="relative w-full h-48 bg-gray-200 rounded-lg overflow-hidden"
                  >
                    <img
                      src={picture}
                      alt={`Project Image ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
              {pictures.length > 5 && (
                <button
                  className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white font-medium text-sm px-4 py-2 rounded-md z-10 shadow-lg hover:bg-opacity-70"
                  onClick={() => console.log("See all photos clicked")}
                >
                  +{pictures.length - 5} more photos
                </button>
              )}
            </div>
          </div>
  
          {/* Close and Copy Buttons */}
          <button
            className="absolute top-4 left-4 bg-white bg-opacity-80 text-gray-900 rounded-full w-10 h-10 flex items-center justify-center shadow-md hover:bg-gray-100 focus:outline-none z-20"
            onClick={onClose}
          >
            &times;
          </button>
          <button
            className="absolute top-4 right-4 bg-white bg-opacity-80 text-gray-900 rounded-full w-10 h-10 flex items-center justify-center shadow-md hover:bg-gray-100 focus:outline-none z-20"
            onClick={handleCopyLink}
            aria-label="Copy Link"
          >
            {linkCopied ? <FaCheck className="text-green-500" /> : <FaCopy />}
          </button>
        </div>
  
        {/* Content Section */}
        <div className="px-4 py-6 space-y-6">
          {/* Project Name and Location */}
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{project.name}</h1>
              <p className="text-gray-600 text-lg">{project.cityName}</p>
            </div>
  
            {/* CTA Button for Desktop */}
            <div className="hidden lg:block">
              <button
                onClick={handleOpenBookingModal}
                className="mt-4 lg:mt-0 bg-blue-600 text-white py-3 px-6 rounded-lg font-medium text-lg shadow-md hover:bg-blue-500 transition duration-300"
              >
                Request a call
              </button>
            </div>
          </div>
  
          {/* Description */}
          <p className="text-gray-700 leading-relaxed">
            {isDescriptionExpanded
              ? project.description
              : `${project.description.slice(0, 400)}`}
          </p>
          {project.description?.length > 400 && (
            <button
              className="text-blue-600 hover:underline"
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            >
              {isDescriptionExpanded ? "Read Less" : "Read More"}
            </button>
          )}
  
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Key Features
            </h2>
            <ul className="list-disc space-y-2 pl-6">
              {project.projectFeatures.totalLandArea != null &&
                project.projectFeatures.totalLandArea !== 0 && (
                  <li>
                    <strong>Total Land Area:</strong> {project.projectFeatures.totalLandArea} m²
                  </li>
                )}
              {project.projectFeatures.unitsAvailable != null &&
                project.projectFeatures.unitsAvailable !== 0 && (
                  <li>
                    <strong>Units Available:</strong> {project.projectFeatures.unitsAvailable}
                  </li>
                )}
              {project.projectFeatures.completionDate != null &&
                project.projectFeatures.completionDate !== "" && (
                  <li>
                    <strong>Completion Date:</strong> {formatDate(project.projectFeatures.completionDate)}
                  </li>
                )}
              {project.projectFeatures.yearsOfLease != null &&
                project.projectFeatures.yearsOfLease !== 0 && (
                  <li>
                    <strong>Years of Lease:</strong> {project.projectFeatures.yearsOfLease} years
                  </li>
                )}
            </ul>
          </div>
  
          {/* Developer Section */}
          {project.developer && (
            <div className="pt-6 pb-4">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Developed by:
              </h2>
              <div className="flex items-center space-x-4">
                {/* Text on the left 
                <p className="text-xl font-bold text-gray-800">
                  {project.developer.name}
                </p>*/}

                {/* Logo on the right */}
                {project.developer.logo && (
                  <img
                    src={project.developer.logo}
                    alt={`${project.developer.name} Logo`}
                    className="h-16 w-auto object-contain"
                  />
                )}
              </div>
            </div>
          )}

          {/* PDF Links Section */}
          <div className="space-y-4">
            {project.presentationPdfUrl && (
              <div>
                <a
                  href={project.presentationPdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline font-semibold"
                >
                  Presentation
                </a>
              </div>
            )}
            {project.financialModelUrl && (
              <div>
                <a
                  href={project.financialModelUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline font-semibold"
                >
                  Financial Model
                </a>
              </div>
            )}
          </div>

          
          {/* Units Section */}
          <div className="border-t pt-6">
            <h2 className="text-3xl font-bold text-gray-900 mb-6">
              Units Available
            </h2>
            <div className="space-y-6 lg:grid lg:grid-cols-2 lg:gap-6 lg:space-y-0">
              {units.map((unit, index) => (
                <div
                  key={index}
                  className="rounded-lg p-4 shadow-md border border-gray-300 hover:shadow-lg transition transform hover:scale-105"
                >
                  {/* Unit Name */}
                  <div className="flex items-center mb-4">
                    <p className="text-lg font-bold text-gray-900">
                      {unit.unitName || "N/A"}{" "}
                      {unit.bedrooms != null && (
                        <>
                          - {unit.bedrooms}{" "}
                          {Number(unit.bedrooms) > 1 ? "Bedrooms" : "Bedroom"}
                        </>
                      )}
                    </p>
                  </div>
                  {/* Price */}
                  <div className="flex items-center mb-4">
                    <p className="text-lg font-semibold text-green-700">
                      $
                      {unit.price
                        ? new Intl.NumberFormat("en-US").format(unit.price)
                        : "N/A"}
                    </p>
                  </div>

                  {/* Size */}
                  <div className="flex items-center">
                    <p className="text-sm text-gray-700">
                      {unit.buildingArea || "N/A"} m²
                    </p>
                  </div>

                  {/* Unit Pictures */}
                  {unit.pictures && unit.pictures.length > 0 && (
                    <div className="mt-4 rounded-lg overflow-hidden">
                      <ImageCarousel pictures={unit.pictures} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Construction Progress */}
          {project.constructionDetails &&
          (project.constructionDetails.constructionProgress ||
            project.constructionDetails.warrantyPeriod ||
            (project.constructionDetails.constructionMaterials)) && (
            <div className="pt-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4 underline">
                Construction Progress
              </h2>
              <ul className="list-disc space-y-2 pl-6">
                {project.constructionDetails.constructionProgress && (
                  <li className="text-gray-700 text-lg">
                    <strong>Progress:</strong> {project.constructionDetails.constructionProgress} %
                  </li>
                )}
                {project.constructionDetails.warrantyPeriod && (
                  <li className="text-gray-700 text-lg">
                    <strong>Warranty Period:</strong> {project.constructionDetails.warrantyPeriod} years
                  </li>
                )}
                {project.constructionDetails.constructionMaterials &&
                  project.constructionDetails.constructionMaterials && (
                    <li className="text-gray-700 text-lg">
                      <strong>Materials:</strong> {project.constructionDetails.constructionMaterials}
                    </li>
                  )}
              </ul>
            </div>
          )}

          {/* Investment Information */}
          {project.investmentInformation && (
            <div className="pt-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4 underline">
                Investment Information
              </h2>
              <ul className="list-disc space-y-2 pl-6">
                {project.investmentInformation.minROI != null && (
                  <li className="text-gray-700 text-lg">
                    <strong>Minimum ROI:</strong> {project.investmentInformation.minROI}%
                  </li>
                )}
                {project.investmentInformation.maxROI != null && (
                  <li className="text-gray-700 text-lg">
                    <strong>Maximum ROI:</strong> {project.investmentInformation.maxROI}%
                  </li>
                )}
                {project.investmentInformation.breakEvenPeriod != null && (
                  <li className="text-gray-700 text-lg">
                    <strong>Breakeven Period:</strong> {project.investmentInformation.breakEvenPeriod} years
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
  
        {/* Footer Section for Mobile */}
        <div className="block lg:hidden px-4 py-4">
          <button
            onClick={handleOpenBookingModal}
            className="w-full bg-blue-600 text-white py-3 rounded-md font-medium text-lg hover:bg-blue-500 shadow-md transition duration-300"
          >
            Request a call
          </button>
        </div>
      </div>
      <BookingModal
        isOpen={isBookingModalOpen}
        onClose={handleCloseBookingModal}
        onSubmit={handleFormSubmit}
        project={project}
      />
    </div>
  )
};

export default ListingDetails;
